body {
    color: #486d7f;
    font-family: 'Nunito Sans';
}

h1 {
    visibility: hidden;
}

a, a:visited, a:active {
    color: #486d7f;
    text-decoration: underline;
}

ul,
p {
    font-size: 24pt;
    line-height: 150%;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

[data-scroll] {
    visibility: hidden;
}

ul li:before {
    content: "\f13d";
    font-family: "Font Awesome 6 Free";
    padding: 0 10px 0 0;
    font-size: 16pt;
}

span {
    display: inline-block;
}

span.whitespace {
    display: initial;
}

.cssanimation {
    animation-duration: 1s;
    animation-fill-mode: both;
    /** Enable hardware acceleration **/
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}
span.leFadeInRight { animation-name: leFadeInRight }
@keyframes leFadeInRight {
    from {
        opacity: 0;
        transform: translateX(60px);
    }
    to { opacity: 1 }
}
span.leScaleYIn { animation-name: leScaleYIn }
@keyframes leScaleYIn {
    from {
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }
    50% { opacity: 1 }
}

span.leBlurIn { animation-name: leBlurIn }
@keyframes leBlurIn {
    from {
        transform: scaleX(0.2);
        filter: blur(20px);
        opacity: 0;
    }
}
span.leBlurOut { animation-name: leBlurOut }
@keyframes leBlurOut {
    to {
        transform: scaleX(0.2);
        filter: blur(20px);
        opacity: 0;
    }
}

.fadeInBottom { animation-name: fadeInBottom }
@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(48px);
    }
    to { opacity: 1 }
}

.leRotateSkateInRight { animation-name: leRotateSkateInRight }
@keyframes leRotateSkateInRight {
    from {
        transform: scaleX(0.2) translateX(100px);
        opacity: 0;
    }
}

.fadeInLeft { animation-name: fadeInLeft }
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to { opacity: 1 }
}

.fadeInRight { animation-name: fadeInRight }
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(100px);
    }
    to { opacity: 1 }
}

.navbar {
    padding: 0;
}

.nav-logo {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
}

.hero {
    margin-bottom: 96px;
}

.hero-image-container {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 1000px;
    position: relative;
    overflow: hidden;
    background-color: #486d7f;
}

.hero-image-container-content {
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.hero-image-container-content > .container,
.hero-image-container-content > .container > .row {
    height: 100%;
}

.hero h1 {
    color: white;
    font-size: 68pt;
    margin-bottom: 232px;
}

.hero img {
    background-size: auto auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero svg {
    width: 100%;
    height: 56%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
}

h2 {
    font-size: 40pt;
    padding: 48px 0 12px 0;
}


.header-quote {
    margin-bottom: 0;
    padding-bottom: 0;
}

.header-quote i.fa-solid {
    opacity: 0.2;
}

.header-quote i.fa-solid:first-child {
    padding-right: 24px;;
}

.header-quote i.fa-solid:last-child {
    padding-left: 24px;;
}

.contact-inner-box,
.contact-box {
    overflow: hidden;
}

.floating-icon {
    float: left;
    margin-top: 23px;
    margin-right:10px;
    font-size: 32pt;

}

h4 {
    margin: 25px 0 15px;
    padding: 0 15px;
    font-size: 32pt;
}

.contact-info {
    padding: 0 15px;
}


.container-legal h1 {
    visibility: visible;
    font-size: 40pt;
    padding: 48px 0 12px;
}

.container-legal h2 {
    font-size: 36pt;
}

.container-legal h3 {
    font-size: 32pt;
}

.container-legal h4 {
    font-size: 24pt;
    padding: 0;
    font-weight: bolder;
}

.container-legal h5 {
    font-size: 18pt;
}



footer {
    margin-top: 128px;
}

footer a, footer a:visited, footer a:active, footer a:hover {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}

footer .link-seperator {
    opacity: 0.4;
    padding: 0 2px;
}

footer .icon-container {
    width: 128px;
    height: 128px;
}

.footer-hero-image-container {
    width: 100%;
    height: 290px;
    position: relative;
    overflow: hidden;
    background-color: #486d7f;
    background-size: cover;
    background-position: center center;
}

footer img {
    background-size: auto auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:2;
}

footer .footer-legal {
    background-color: #284553;
    color: white;
}

footer .footer-legal > div > div {
    height: 300px;
}

footer .footer-imprint {
    font-size: 48pt;
}

footer p {
    margin-bottom: 0;
}



@media (max-width: 767.98px) {

    .nav-logo {
        max-width: 320px;
    }

    .hero {
        margin-bottom: 48px;
    }

    .hero-image-container {
        height: 500px;
    }
    .hero h1 {
        font-size: 34pt;
        margin-bottom: 114px;
    }

    .hero svg {
        height: 54%;
    }

    .hero img {
        width: initial;
        height: initial;
    }

    h2 {
        font-size: 24pt;
        padding: 24px 0 6px;
    }

    .floating-icon {
        font-size: 18pt;

    }

    h4 {
        font-size: 18pt;
    }

    ul,
    p {
        font-size: 16pt;
    }

    footer {
        margin-top: 64px;
    }

    .footer-hero-image-container {
        height: 145px;
    }

    footer svg {
        height: 145px;
    }

    footer .footer-imprint {
        font-size: 24pt;
    }

    footer .icon-container, footer .icon-container svg {
        width: 64px;
        height: 64px;
    }

    footer .footer-legal > div > div {
        height: 150px;
    }

}
